<!-- 合作机构 -->
<template>
   <div class='organization-container'>
    <div class="container">
      <h1>培养目标</h1>
      <div class="school-container">
          <div class="item">
            <img src="../../assets/images/item1.png" alt="">
            <p>西南大学</p>
          </div>
          <div class="item">
            <img src="../../assets/images/item2.png" alt="">
            <p>贵阳幼儿师范高等专科学校</p>
          </div>
      </div>
    </div>
   </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
  
    };
  },
  //方法集合
  methods: {
  
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  
  },
  }
</script>
<style lang='less' scoped>
.organization-container {
  text-align: center;
  margin-bottom: 129px;
  .school-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    .item{
      &:nth-child(1){
        margin-right: 62px;
      }
      p{
        font-size: 18px;
        color: #474747;
        padding-top: 20px;
      }
    }
  }
}
</style>